import React, { Component, createContext, useEffect, useState } from "react";
import { auth, generateUserDocument } from "./firebase";
import Loading from "./components/Loading";

export const UserContext = createContext({ user: null });

const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  // Hooks
  useEffect(() => {
    const loggingIn = async () => {
      let usr;
      auth.onAuthStateChanged(async (userAuth) => {
        usr = await generateUserDocument(userAuth);
        setUser(usr);
      });
    };
    loggingIn();
  }, []);

  useEffect(() => {
    if (user !== null) {
      setLoadingUser(false);
    }
  }, [user]);

  // return loadingUser ? (
  //   <Loading />
  // ) : (
  //   <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  // );

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export default AuthProvider;
