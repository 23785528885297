import { createMuiTheme, fade } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#0290BF",
      // main: "#f02607",
    },
  },
});

export const mainColor = "#0750fa";
export const blackColor = "#080808";
export const lightGreyColor = "#d7d7db";
export const bgColor1 = fade(blackColor, 0.03);
