import { Button, Collapse, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Logo from "../randLogo.png";
import { theme } from "../theme";

const MyHeader = () => {
  const phoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const hist = useHistory();
  const [currentRoute, setCurrentRoute] = useState(hist.location.pathname);

  useEffect(() => {}, [currentRoute]);

  const TabOptions = (props) => {
    const opts = [
      {
        title: "Home",
        size: 2,
        route: "/",
      },
      {
        title: "Agriculture",
        size: 2,
        route: "/agriculture",
      },
      {
        title: "Energy",
        size: 3,
        route: "/energy",
      },
      // {
      //   title: "About Us",
      //   size: 2,
      //   route: "/about-us",
      // },
      // {
      //   title: "Our Sectors",
      //   size: 1,
      // },
      // {
      //   title: "Insights",
      //   size: 1,
      // },
      // {
      //   title: "News",
      //   size: 1,
      // },
      // {
      //   title: "Career",
      //   size: 1,
      // },
      // {
      //   title: "Contact Us",
      //   size: 2,
      // },
    ];
    return (
      <>
        {opts.map((opt) => {
          if (!phoneScreen) {
            return (
              <Grid item xs={opt.size} style={{ width: 70 }}>
                <ListItem
                  button
                  onClick={() => {
                    hist.push(opt.route);
                    setCurrentRoute(opt.route);
                  }}
                >
                  <Typography component="div">
                    <Box
                      fontWeight={500}
                      fontSize={13}
                      style={{
                        color:
                          opt.route === currentRoute
                            ? theme.palette.primary.main
                            : "#353738",
                      }}
                    >
                      <Typography variant="subtitle1">
                        <strong>{opt.title}</strong>
                      </Typography>
                    </Box>
                  </Typography>
                </ListItem>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={12}>
                <ListItem
                  button
                  onClick={() => {
                    props.close();
                    hist.push(opt.route);
                  }}
                >
                  <Typography component="div">
                    <Box
                      fontWeight={500}
                      fontSize={13}
                      style={{ color: "grey" }}
                    >
                      {opt.title}
                    </Box>
                  </Typography>
                </ListItem>
              </Grid>
            );
          }
        })}
      </>
    );
  };
  const [expand, setExpand] = useState(false);
  return (
    <>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "rgba(255,255,255,0.5)" }}
      >
        {phoneScreen !== true ? (
          <Toolbar
            style={{
              backgroundColor: "transparent",
              paddingLeft: 100,
              paddingRight: 100,
            }}
          >
            <img src={Logo} alt="logo" style={{ marginRight: 30 }} />
            <Grid container>
              <TabOptions />
            </Grid>
          </Toolbar>
        ) : (
          <Toolbar
            style={{
              backgroundColor: "white",
              padding: theme.spacing(2),
              paddingRight: theme.spacing(5),
            }}
          >
            <Grid container>
              <Grid item xs={11}>
                <img src={Logo} alt="logo" height={50} width={150} style={{}} />
              </Grid>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={() => {
                    const x = !expand;
                    setExpand(x);
                  }}
                >
                  {expand === true ? (
                    <ExpandLess color="primary" />
                  ) : (
                    <ExpandMore color="primary" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        )}
        <Collapse
          in={expand}
          timeout="auto"
          unmountOnExit
          style={{ backgroundColor: "white" }}
        >
          <Grid container direction="column">
            <TabOptions
              close={() => {
                setExpand(false);
              }}
            />
          </Grid>
        </Collapse>
      </AppBar>
    </>
  );
};

export default MyHeader;
