import { Paper } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SWRConfig } from "swr";
import Footer from "./components/Footer";
import MyHeader from "./components/Header";
import { fetchData } from "./functions";
import Agriculture from "./pages/Agriculture";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { theme } from "./theme";
import { rrfProps, store } from "./store";
import Darkbg1 from "./assets/darkbg2.jpg";
import Energy from "./pages/Energy";
const MyRouter = () => {
  return (
    <>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <ThemeProvider theme={theme}>
            <SWRConfig
              value={{
                fetcher: (indicator) => fetchData(indicator),
              }}
            >
              <Paper
                elevation={0}
                style={{
                  height: "100vh",
                  margin: 0,
                  position: "fixed",
                  overflow: "auto",
                  top: 0,
                  width: "100%",
                  backgroundImage: `url(${Darkbg1})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    height: "100vh",
                  }}
                >
                  <Router>
                    <MyHeader />
                    <Switch>
                      <Route
                        exact
                        path="/agriculture"
                        component={Agriculture}
                      />
                      <Route exact path="/energy" component={Energy} />
                      <Route exact path="/login" component={LoginPage} />
                      <Route exact path="/" component={HomePage} />
                    </Switch>
                    <Footer />
                  </Router>
                </div>
              </Paper>
            </SWRConfig>
          </ThemeProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </>
  );
};

export default MyRouter;
