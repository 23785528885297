import { createMuiTheme, LinearProgress, MuiThemeProvider, Paper } from '@material-ui/core';
import React from 'react';


const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#00d15e",
      }
    }
  }
);

function Loading(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Paper component="div" elevation={5}>
        <LinearProgress color="primary" />
      </Paper>
    </MuiThemeProvider>
  );
}

export default Loading;
