/* eslint-disable react/require-default-props */
import { createMuiTheme } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00d15e',
    },
  },
});

const TabPage = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id="tab-page-${index"
      style={{
        marginTop: theme.spacing(2),
      }}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPage.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPage;
