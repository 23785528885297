import { useState, useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebaseApp } from "../firebase";

import PropTypes from "prop-types";

const FirebaseAuth = (props) => {
  const [show, setShow] = useState(false);

  const firebaseAuthConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebaseApp.auth.EmailAuthProvider.PROVIDER_ID,
      firebaseApp.auth.GoogleAuthProvider.PROVIDER_ID,
      firebaseApp.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccess: props.onSuccess,
    },
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div>
      {show && (
        <StyledFirebaseAuth
          uiConfig={firebaseAuthConfig}
          firebaseAuth={firebaseApp.auth()}
        />
      )}
    </div>
  );
};

FirebaseAuth.propTypes = {
  onSuccess: PropTypes.string.isRequired,
};

export default FirebaseAuth;
