import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Paper,
  Popover,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { ExpandMore, InfoOutlined, LockOutlined } from "@material-ui/icons";
import { theme } from "../theme";
import TabPage from "./TabPage";
import { Bar, Line } from "react-chartjs-2";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { isLoaded } from "react-redux-firebase";

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

const AirbnbSlider = withStyles({
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);

const Graphs = (props) => {
  const {
    data,
    firestoreCountries,
    firestoreCommodities,
    indicator,
    memberships,
    countries,
    setCountries,
    setdownloadData,
  } = props;

  const [commodity, setCommodity] = useState(
    firestoreCommodities.filter((com) =>
      memberships.data[indicator].commodities.includes(
        com.value.toLocaleLowerCase()
      )
    )[0].value
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [range, setRange] = React.useState([1, 100]);
  const [time, setTime] = useState("yearly");
  const [search, setSearch] = useState("");
  const [openedPopover, setOpenedPopover] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const popoverAnchor = useRef(null);

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false);
  };

  const classes = useStyles();

  const searchHandler = (event) => {
    setSearch(event.target.value);
  };

  const handleChangeTime = (event, newTime) => {
    setTime(newTime);
  };

  const handleChange = (event, newValue) => {
    setRange(newValue);
  };

  useEffect(() => {}, [search]);

  const phoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function scale(number, inMin, inMax, outMin, outMax) {
    const y = ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    const ret = Math.ceil(parseFloat(y));
    return ret;
  }

  function valuetext(value) {
    return `${scale(value[0], 1, 100, 1960, 2019)} to ${scale(
      value[1],
      1,
      100,
      1960,
      2019
    )}`;
  }

  const getData = (key) => {
    let dataList = {};
    for (
      let i = scale(range[0], 1, 100, 1960, 2019);
      i <= scale(range[1], 1, 100, 1960, 2019);
      i++
    ) {
      dataList[i] = undefined;
    }
    if (data[key] !== undefined) {
      data[key][commodity].map((val, index) => {
        if (index !== 0) {
          if (
            val[0] >= scale(range[0], 1, 100, 1960, 2019) &&
            val[0] <= scale(range[1], 1, 100, 1960, 2019)
          ) {
            // dataList.push(val[1]);
            dataList[val[0]] = val[1]?.toFixed(2);
          }
        }
      });
      // console.log("klk: ", Object.values(dataList));
      return Object.values(dataList);
    }
    return dataList;
  };

  const getKeys = () => {
    let dataList = [];
    for (
      let i = scale(range[0], 1, 100, 1960, 2019);
      i <= scale(range[1], 1, 100, 1960, 2019);
      i++
    ) {
      if (
        i >= scale(range[0], 1, 100, 1960, 2019) &&
        i <= scale(range[1], 1, 100, 1960, 2019)
      ) {
        dataList.push(i);
      }
    }
    return dataList;
  };

  const addToCountries = (country) => {
    if (countries.includes(country)) return;
    setCountries((state) => [...state, country]);
    setSearch("");
  };

  const removeFromCompareCountries = (c) => {
    setCountries((state) => state.filter((item) => item !== c));
  };

  const handleCommodityChange = (event) => {
    setCommodity(event.target.value);
  };

  const chartData = {
    labels: getKeys(),
    datasets: countries
      .filter((c) => memberships.data[indicator].countries.includes(c.code))
      .map((com) => {
        const countryData = getData(com.value);
        return {
          label: com.name,
          data: countryData,
          backgroundColor: firestoreCountries.filter(
            (item) => item.value === com.value
          )[0].color,
          borderColor: firestoreCountries.filter(
            (item) => item.value === com.value
          )[0].color,
          borderWidth: 1,
        };
      }),
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: {
        title: {
          display: true,
          text: "Price in USD",
        },
        min: 0,
        ticks: {},
        grid: {
          display: false,
        },
      },
      xAxes: {
        title: {
          display: true,
          text: "Year",
        },
        ticks: {},
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getGraph = (type) => {
    switch (type) {
      case "line":
        return (
          <Line
            data={chartData}
            width={phoneScreen ? 50 : 50}
            // height={"80vh"}
            height={300}
            options={options}
          />
        );

      default:
        return (
          <Bar
            data={chartData}
            width={phoneScreen ? 50 : 50}
            height={300}
            // options={options}
            options={options}
          />
        );
    }
  };

  useEffect(() => {
    const d = countries.map((c) => {
      return { [c.value]: data[c.value][commodity] };
    });
    // console.log("Data: ", d);
    setdownloadData(d);
  }, [countries, commodity]);

  return (
    <>
      {isLoaded(firestoreCountries) && (
        <div style={{ overflow: "auto" }}>
          <Tabs
            value={selectedTab}
            onChange={(event, i) => {
              setSelectedTab(i);
            }}
            textColor="primary"
            indicatorColor="primary"
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            <Tab
              label="Bar chart"
              color="primary"
              style={{ textTransform: "none" }}
            />
            <Tab
              label="Line graph"
              color="primary"
              style={{ textTransform: "none" }}
            />
            <Toolbar style={{ width: "90%" }}>
              <Grid container>
                <Grid item xs={8}></Grid>
                <Grid item xs={1}>
                  {/* <IconButton>
                    <Share
                      fontSize="medium"
                      style={{ color: theme.palette.text.secondary }}
                    />
                  </IconButton> */}
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={1}>
                  <IconButton
                    ref={popoverAnchor}
                    aria-owns="mouse-over-popover"
                    aria-haspopup="true"
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}
                  >
                    <InfoOutlined
                      fontSize="medium"
                      style={{
                        color: theme.palette.text.secondary,
                        // marginRight: theme.spacing(2),
                      }}
                    />
                  </IconButton>
                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                      paper: classes.popoverContent,
                    }}
                    open={openedPopover}
                    anchorEl={popoverAnchor.current}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      onMouseEnter: popoverEnter,
                      onMouseLeave: popoverLeave,
                    }}
                  >
                    <div
                      style={{
                        width: "25vw",
                        padding: theme.spacing(3),
                        backgroundColor: "rgba(16,16,16,0.8)",
                        color: "white",
                      }}
                    >
                      <Typography variant="h4" color="primary">
                        Methodology
                      </Typography>
                      <Typography variant="h6" color="primary">
                        Imports and Exports
                      </Typography>
                      <p>
                        The imports and exports data listed is for raw or
                        unprocessed products and is represented in USD values.
                        The data includes re-exports and re-imports.
                      </p>
                      <Typography variant="h6" color="primary">
                        Sources
                      </Typography>
                      <p>
                        The data was collected from the following multiple
                        sources:
                        <br />
                        UN Comtrade database; Botswana Agriculture Marketing
                        Board; Mordor Intelligence; AgFlow; Price Discovery;
                        International Grain Council; Agriculture Commodity;
                        TrendEconomy and Knoema. Data was also collected from
                        the respective country websites. in cases where data was
                        represented in different quantities assessments were
                        done according to the nominal price and then export
                        values were calculated.
                      </p>
                      <Typography variant="h6" color="primary">
                        Currency conversions
                      </Typography>

                      <p>
                        Local currency values were converted to the average US$
                        exchange rates to ensure comparable values for all
                        imports and exports. In some cases where findings
                        revealed different values from different sources, the
                        arithmetic average of the different sources was
                        calculated.
                      </p>

                      <Typography variant="h6" color="primary">
                        Prices
                      </Typography>

                      <p>The prices are in US$ per KG.</p>
                      <Typography variant="h6" color="primary">
                        Sources
                      </Typography>
                      <p>
                        Data from prices was obtained from the following
                        multiple sources: Trading Economics, Macrotrends,
                        TradingCharts, Business Insider, IFC Market, Chirwa,
                        Themba and Odhiambo (2016). Data was also collected from
                        country marketing boards.
                      </p>
                      <Typography variant="h6" color="primary">
                        Currency Conversions
                      </Typography>

                      <p>
                        In cases where prices were given in the local currency,
                        the arithmetic average US$ exchange rate was used to
                        convert the prices into US dollar terms. Products in
                        different units of measurement were converted to Kgs per
                        unit. In cases where different prices were quoted from
                        different sources, an arithmetic average was calculated
                        to obtain the comparable per unit prices.
                      </p>
                    </div>
                  </Popover>
                </Grid>
              </Grid>
            </Toolbar>
          </Tabs>
          <TabPage value={selectedTab} index={0}>
            <Paper
              style={{
                height: "50vh",
                display: "flex",
                alignItems: "center",
                // backgroundColor: `rgba(255,255,255, 0.3)`,
              }}
            >
              {getGraph("bar")}
            </Paper>
            <div
              style={{
                marginTop: 10,
                paddingLeft: 13,
                paddingRight: 13,
                width: "95%",
              }}
            >
              <AirbnbSlider
                ThumbComponent={AirbnbThumbComponent}
                getAriaLabel={(index) =>
                  index === 0 ? "Minimum price" : "Maximum price"
                }
                defaultValue={[1, 100]}
                onChange={handleChange}
              />
              <Typography
                gutterBottom
                style={{ textAlign: "center", marginTop: 10 }}
              >
                {valuetext(range)}
              </Typography>
            </div>
          </TabPage>
          <TabPage value={selectedTab} index={1}>
            <Paper
              style={{
                height: "50vh",
                display: "flex",
                alignItems: "center",
                // backgroundColor: `rgba(255,255,255, 0.3)`,
              }}
            >
              {getGraph("line")}
            </Paper>
            <div
              style={{
                marginTop: 10,
                paddingLeft: 13,
                paddingRight: 13,
                width: "95%",
              }}
            >
              <AirbnbSlider
                ThumbComponent={AirbnbThumbComponent}
                getAriaLabel={(index) =>
                  index === 0 ? "Minimum price" : "Maximum price"
                }
                defaultValue={[1, 100]}
                onChange={handleChange}
              />
              <Typography
                gutterBottom
                style={{ textAlign: "center", marginTop: 10 }}
              >
                {valuetext(range)}
              </Typography>
            </div>
          </TabPage>
          <Container style={{ marginTop: theme.spacing(7) }}>
            <Grid
              container
              style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                sm={6}
                lg={6}
                alignContent="flex-start"
                style={{ overflow: "auto" }}
              >
                <Grid item xs={12} lg={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "bold",
                    }}
                    color="primary"
                  >
                    Time
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <ToggleButtonGroup
                    value={time}
                    exclusive
                    size="small"
                    onChange={handleChangeTime}
                    aria-label="Time"
                  >
                    <ToggleButton value="yearly" aria-label="left aligned">
                      <Typography variant="body1">Yearly</Typography>
                    </ToggleButton>
                    <ToggleButton
                      value="quarterly"
                      aria-label="centered"
                      disabled
                    >
                      <Typography variant="body1">Quarterly</Typography>
                    </ToggleButton>
                    <ToggleButton
                      value="monthly"
                      aria-label="right aligned"
                      disabled
                    >
                      <Typography variant="body1">Monthly</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5} lg={5}>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                  }}
                  color="primary"
                >
                  Commodity
                </Typography>
                <FormControl>
                  <Select
                    value={commodity}
                    onChange={handleCommodityChange}
                    displayEmpty
                  >
                    {firestoreCommodities.map((comm) => {
                      return (
                        <MenuItem
                          disabled={
                            !memberships.data[indicator].commodities.includes(
                              comm.value
                            )
                          }
                          value={comm.value}
                        >
                          {comm.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {/* -----------------COUNTRY SELECTION------------------- */}
              <Grid container item xs={12} lg={12}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    variant="body1"
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(1),
                      fontWeight: "bold",
                    }}
                    color="primary"
                  >
                    Countries
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  style={
                    phoneScreen
                      ? { height: "50vh", overflow: "auto" }
                      : { overflow: "auto" }
                  }
                >
                  <Accordion expanded={expanded} style={!phoneScreen ? {} : {}}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      IconButtonProps={{
                        onClick: () => {
                          setSearch("");
                          setExpanded((state) => !state);
                        },
                      }}
                      id="panel1a-header"
                    >
                      <Grid container>
                        <Grid item xs={6} sm={7} lg={7}>
                          <TextField
                            fullWidth
                            placeholder="Find a country"
                            variant="outlined"
                            value={search}
                            onChange={searchHandler}
                          />
                        </Grid>
                        <Grid item xs={5} lg={5}>
                          <Typography
                            style={{
                              marginLeft: theme.spacing(6),
                              marginTop: theme.spacing(3),
                            }}
                          >
                            <strong>{`Slected countries (`}</strong>
                            {
                              countries.filter((c) =>
                                memberships.data[indicator].countries.includes(
                                  c.code
                                )
                              ).length
                            }
                            <strong>{`)`}</strong>
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item xs={5} lg={4}>
                          <Typography
                            variant="body1"
                            style={{
                              marginTop: theme.spacing(3),
                              marginBottom: theme.spacing(1),
                              fontWeight: "bold",
                            }}
                            color="primary"
                          >
                            Countries
                          </Typography>
                          <List
                            style={
                              phoneScreen
                                ? { height: "25vh", overflow: "auto" }
                                : { height: "25vh", overflow: "auto" }
                            }
                          >
                            {firestoreCountries
                              .filter((country) => {
                                if (search !== "")
                                  return country.name
                                    .toLocaleLowerCase()
                                    .includes(
                                      search
                                        .trimEnd()
                                        .trimStart()
                                        .toLocaleLowerCase()
                                    );
                                return true;
                              })
                              .map((cntry) => {
                                return (
                                  <ListItem
                                    button
                                    disabled={
                                      !memberships.data[
                                        indicator
                                      ].countries.includes(cntry.code)
                                    }
                                    divider
                                    onClick={() => {
                                      addToCountries(cntry);
                                    }}
                                    style={{
                                      paddingLeft: theme.spacing(1),
                                      paddingRight: 0,
                                    }}
                                  >
                                    <ListItemText>
                                      <div style={{ display: "flex" }}>
                                        <ListItemAvatar style={{ width: 15 }}>
                                          {memberships.data[
                                            indicator
                                          ].countries.includes(cntry.code) ? (
                                            <>
                                              {countries.filter(
                                                (country) =>
                                                  country.value === cntry.value
                                              ).length > 0 && (
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      theme.palette.primary
                                                        .main,
                                                    width: 5,
                                                    height: 20,
                                                    marginRight:
                                                      theme.spacing(1),
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <LockOutlined />
                                            </>
                                          )}
                                        </ListItemAvatar>
                                        <Typography
                                          variant="subtitle2"
                                          color="textSecondary"
                                        >
                                          {cntry.name}
                                        </Typography>
                                      </div>
                                    </ListItemText>
                                    <ListItemAvatar>
                                      <Avatar
                                        style={{
                                          width: 16,
                                          height: 16,
                                        }}
                                        alt={cntry.code}
                                        src={cntry.flag}
                                      />
                                    </ListItemAvatar>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </Grid>
                        <Grid
                          container
                          xs={7}
                          lg={8}
                          alignContent="flex-start"
                          style={{ paddingLeft: theme.spacing(5) }}
                        >
                          <Grid item xs={12} lg={12} style={{}}>
                            <Typography
                              variant="body1"
                              color="primary"
                              style={{
                                marginTop: theme.spacing(3),
                                marginBottom: theme.spacing(2),
                                fontWeight: "bold",
                              }}
                            >
                              <strong>Selected countries</strong>
                            </Typography>
                          </Grid>
                          <Grid item lg={12}>
                            <Divider />
                          </Grid>
                          <Grid item lg={12}>
                            <div>
                              {countries
                                .filter((c) =>
                                  memberships.data[
                                    indicator
                                  ].countries.includes(c.code)
                                )
                                .map((cntry) => {
                                  return (
                                    <Chip
                                      key={cntry}
                                      variant="outlined"
                                      avatar={
                                        <Avatar
                                          style={{
                                            width: 16,
                                            height: 16,
                                          }}
                                          alt={cntry.code}
                                          src={cntry.flag}
                                        />
                                      }
                                      clickable
                                      onDelete={() => {
                                        removeFromCompareCountries(cntry);
                                      }}
                                      label={cntry.name}
                                      size="medium"
                                      style={{
                                        margin: theme.spacing(1),
                                        marginLeft: 0,
                                        color: cntry.color,
                                        borderColor: cntry.color,
                                      }}
                                    />
                                  );
                                })}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setSearch("");
                          setCountries([]);
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Clear</strong>
                        </Typography>
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          setSearch("");
                          setExpanded((state) => !state);
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Close</strong>
                        </Typography>
                      </Button>
                    </AccordionActions>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
};

export default Graphs;
