import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { theme } from "../theme";
import { Typography, useMediaQuery } from "@material-ui/core";

function HomePage() {
  const phoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div
          style={{
            padding: phoneScreen ? 5 : theme.spacing(10),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
          }}
        >
          <Typography variant="h1" style={{ fontWeight: "bolder" }}>
            RandSandton
          </Typography>
          <Typography
            variant="h1"
            style={{ marginLeft: theme.spacing(15), fontWeight: "bolder" }}
          >
            Analytica
          </Typography>
          <Paper
            style={{
              margin: theme.spacing(10),
              padding: theme.spacing(10),
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            <Typography variant="h2">Data Analytics</Typography>
            <p style={{ textAlign: "center" }}>
              <Typography variant="h6">
                With our advanced team of data scientists and analysts, we
                capture, clean and synthesize data for modelling and
                visualization. Our findings enable information dissemination,
                problem solving and knowledge creation and transfer.
              </Typography>
            </p>
            <Typography variant="h2" style={{ marginTop: theme.spacing(15) }}>
              What we do
            </Typography>
            <ul style={{ paddingLeft: theme.spacing(2) }}>
              <li>
                <Typography variant="h6">
                  We deliver effective predictive and personalised business
                  expansion plans.
                </Typography>
              </li>
              <li>
                <Typography variant="h6">
                  Our situational analysis and impact assessments help you to
                  have an understanding of the interventions and development
                  frameworks.
                </Typography>
              </li>
            </ul>
          </Paper>
        </div>
      </div>
    </>
  );
}

export default HomePage;
