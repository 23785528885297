import React from "react";
import Button from "@material-ui/core/Button";
import * as XLSX from "xlsx";

export const ExportToExcel = ({ Data, fileName }) => {
  const exportToCSV = (Data, fileName) => {
    console.log("DATA: ", Data);
    const wb = XLSX.utils.book_new();
    Data.map((d) => {
      var ws_data = [];
      Object.values(d).map((dd) => {
        dd.map((x) => {
          ws_data.push(x);
        });
      });
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      XLSX.utils.book_append_sheet(wb, ws, Object.keys(d)[0]);
    });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <Button
      variant="text"
      color="primary"
      disabled={Data === null || Data.length === 0}
      onClick={(e) => exportToCSV(Data, fileName)}
    >
      Excel
    </Button>
  );
};
