import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
export const firebaseConfig = {
  apiKey: "AIzaSyDXgukR4S1yweT62NcRtjGFxnnxqACViHI",
  authDomain: "randsandton-885ed.firebaseapp.com",
  projectId: "randsandton-885ed",
  storageBucket: "randsandton-885ed.appspot.com",
  messagingSenderId: "635618186980",
  appId: "1:635618186980:web:323c8c05093bb4ebc1bef1"
};

// firebase.initializeApp({
//   apiKey: "AIzaSyDXgukR4S1yweT62NcRtjGFxnnxqACViHI",
//   authDomain: "randsandton-885ed.firebaseapp.com",
//   projectId: "randsandton-885ed",
//   storageBucket: "randsandton-885ed.appspot.com",
//   messagingSenderId: "635618186980",
//   appId: "1:635618186980:web:81aa3d350ee7af4fc1bef1",
// });

firebase.initializeApp(firebaseConfig);

// Initialize Firebase
export const _firebase = firebase;
export const firebaseApp = firebase;
export const db = firebaseApp.firestore();
export const storage = firebaseApp.storage();
export const storageRef = storage.ref();
export const auth = firebase.auth();
export default firebase;

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  auth.signInWithPopup(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = db.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { email, displayName } = user;
    try {
      await userRef.set({
        displayName,
        email,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await db.doc(`users/${uid}`).get();

    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

