/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { theme } from "../theme";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShowChart from "@material-ui/icons/ShowChart";
import {
  Button,
  CircularProgress,
  ListItemAvatar,
  useMediaQuery,
} from "@material-ui/core";
import Graphs from "../components/GraphsComponent";
import { CloudDownloadOutlined } from "@material-ui/icons";
import SignIn from "./SignInPage";
import useSWR from "swr";
import { useSelector } from "react-redux";
import {
  firestoreConnect,
  isLoaded,
  useFirebaseConnect,
} from "react-redux-firebase";
import { ExportToExcel } from "../components/exportToExcel";

const { tableau } = window;

const TableauComponent = () => {
  const ref = useRef(null);
  const initViz = () => {
    const url =
      "https://public.tableau.com/views/Energysector/Dashboard1?:language=en-US&:display_count=n&:origin=viz_share_link";
    const options = {
      hideTabs: true,
      onFirstInteractive: function () {
        console.log("Run this code when the viz has finished loading.");
      },
      width: "100%",
      height: "90vh",
    };

    new tableau.Viz(ref.current, url, options);
    // Create a viz object and embed it in the container div.
  };

  useEffect(initViz, []);

  return (
    <>
      <div>
        <div ref={ref}></div>
      </div>
    </>
  );
};

function Energy() {
  const phoneScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div style={{ overflow: "auto  " }}>
        <div
          style={{
            margin: phoneScreen ? 5 : theme.spacing(3),
            marginRight: 0,
            padding: phoneScreen ? 5 : theme.spacing(10),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(3),
            overflow: "auto",
          }}
        >
          <TableauComponent />
        </div>
      </div>
    </>
  );
}

export default Energy;

// export default firestoreConnect([
//   {
//     collection: "countries",
//     orderBy: ["name", "asc"],
//   },
//   {
//     collection: "commodities",
//     orderBy: ["name", "asc"],
//   },
//   {
//     collection: "memberships",
//   },
//   {
//     collection: "indicators",
//   },
// ])(Energy);
