import { theme } from "../theme";

const colors = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  "#C46210",
  "#E52B50",
  "#FFBF00",
  "#FF7E00",
  "#841B2D",
  "#BFFF00",
  "#000000",
  "#88540B",
  "#CC5500",
  "#00CC99",
  "#9FA91F",
];
export const _countries = [
  {
    name: "Botswana",
    value: "botswana",
    color: "",
    flag: "",
    code: "bw"
  },
  {
    name: "Ethiopia",
    value: "ethiopia",
    color: "",
    flag: "",
    code: "et"
  },
  {
    name: "Ghana",
    value: "ghana",
    color: "",
    flag: "",
    code: "gh"
  },
  {
    name: "Kenya",
    value: "kenya",
    color: "",
    flag: "",
    code: "ke"
  },
  {
    name: "Lesotho",
    value: "lesotho",
    color: "",
    flag: "",
    code: "ls"
  },
  {
    name: "Malawi",
    value: "malawi",
    color: "",
    flag: "",
    code: "mw"
  },
  {
    name: "Nigeria",
    value: "nigeria",
    color: "",
    flag: "",
    code: "ng"
  },
  {
    name: "Rwanda",
    value: "rwanda",
    color: "",
    flag: "",
    code: "rw"
  },
  {
    name: "Senegal",
    value: "senegal",
    color: "",
    flag: "",
    code: "sn"
  },
  {
    name: "South Africa",
    value: "south-africa",
    color: "",
    flag: "",
    code: "za"
  },
  {
    name: "Tanzania",
    value: "tanzania",
    color: "",
    flag: "",
    code: "tz"
  },
  {
    name: "Uganda",
    value: "uganda",
    color: "",
    flag: "",
    code: "ug"
  },
  {
    name: "Zambia",
    value: "zambia",
    color: "",
    flag: "",
    code: "zm"
  },
];

for (var x = 0; x < _countries.length; x++) {
  _countries[x].color = colors[x];
  _countries[x].flag = `https://www.countryflags.io/${_countries[x].code}/flat/16.png`;
}

// console.log("Counwntries: ", _countries);

export const countries = _countries.sort((a, b) => {
  return a.name - b.name;
});

export const getCountry = (value) =>{
  return countries.filter(item => item.value === value)[0]
}