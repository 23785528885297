import { theme } from "../theme";

const colors = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  "#C46210",
  "#E52B50",
  "#FFBF00",
  "#FF7E00",
  "#841B2D",
  "#BFFF00",
  "#000000",
  "#88540B",
  "#CC5500",
  "#00CC99",
  "#9FA91F",
];

const _commodities = [
  {
    name: "Tobacco",
    value: "tobacco",
    color: "",
  },
  {
    name: "Cotton",
    value: "cotton",
    color: "",
  },
  {
    name: "Wheat",
    value: "wheat",
    color: "",
  },
  {
    name: "Rice",
    value: "rice",
    color: "",
  },
  {
    name: "Maize Grain",
    value: "maize-grain",
    color: "",
  },
  {
    name: "Sugar",
    value: "sugar",
    color: "",
  },
  {
    name: "Cocoa",
    value: "cocoa",
    color: "",
  },
  {
    name: "Coffee",
    value: "coffee",
    color: "",
  },
  {
    name: "Soybean",
    value: "soybean",
    color: "",
  },
  {
    name: "Tea",
    value: "tea",
    color: "",
  },
  {
    name: "Sorghum",
    value: "sorghum",
    color: "",
  },
  {
    name: "Sunflower",
    value: "sunflower seed",
    color: "",
  },
  {
    name: "Apples",
    value: "apples",
    color: "",
  },
  {
    name: "Oranges",
    value: "oranges",
    color: "",
  },
  {
    name: "Grapes",
    value: "grapes",
    color: "",
  },
  {
    name: "Pineapples",
    value: "pineapples",
    color: "",
  },
];

// for (var x = 0; x < _commodities.length; x++) {
//   _commodities[x].color = colors[x];
// }

export const commodities = _commodities.sort((a, b) => {
  return a.name - b.name;
});
