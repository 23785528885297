/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { theme } from "../theme";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShowChart from "@material-ui/icons/ShowChart";
import {
  Button,
  CircularProgress,
  ListItemAvatar,
  useMediaQuery,
} from "@material-ui/core";
import Graphs from "../components/GraphsComponent";
import { CloudDownloadOutlined } from "@material-ui/icons";
import SignIn from "./SignInPage";
import useSWR from "swr";
import { useSelector } from "react-redux";
import {
  firestoreConnect,
  isLoaded,
  useFirebaseConnect,
} from "react-redux-firebase";
import { ExportToExcel } from "../components/exportToExcel";

function Agriculture() {
  const [indicator, setIndicator] = useState("pricing");
  const [countries, setCountries] = useState([]);
  const [downloadData, setDownloadData] = useState(null);

  // const [data, setData] = React.useState(null);
  const { data: pricingData } = useSWR("pricing");
  const { data: importsData } = useSWR("imports");
  const { data: exportsData } = useSWR("exports");

  const phoneScreen = useMediaQuery(theme.breakpoints.down("md"));
  useFirebaseConnect(["countries", "commodities"]);

  const firestoreCountries = useSelector(
    (state) => state.firestore.data.countries
  );

  const firestoreCommodities = useSelector(
    (state) => state.firestore.data.commodities
  );

  const indicators = useSelector((state) => state.firestore.data.indicators);

  const memberships = useSelector((state) => state.firestore.data.memberships);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {}, [indicator]);

  useEffect(() => {
    console.log("NEW DATA: ", downloadData);
  }, [downloadData]);

  return (
    <>
      <div style={{ overflow: "auto  " }}>
        <div
          style={{
            margin: phoneScreen ? 5 : theme.spacing(3),
            marginRight: 0,
            padding: phoneScreen ? 5 : theme.spacing(10),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(3),
            overflow: "auto",
          }}
        >
          <Grid container style={{ height: "60%" }}>
            {/* ---------------------------INDICATORS SELECTION---------------------- */}
            <Grid
              item
              lg={3}
              style={{ paddingLeft: phoneScreen ? 5 : theme.spacing(4) }}
            >
              {isLoaded(indicators) && (
                <Paper elevation={0} style={{ marginTop: theme.spacing(10) }}>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ margin: theme.spacing(2) }}
                  >
                    Indicators
                  </Typography>
                  <Grid container item>
                    <List
                      style={{
                        width: "100%",
                      }}
                    >
                      {Object.values(indicators).map((label) => {
                        return (
                          <ListItem
                            key={label.title}
                            button
                            divider
                            onClick={() => {
                              setIndicator(label.type);
                            }}
                            style={{
                              width: "100%",
                              paddingLeft: theme.spacing(1),
                              paddingRight: 0,
                            }}
                          >
                            <ListItemText>
                              <div style={{ display: "flex" }}>
                                {label.type === indicator && (
                                  <div
                                    style={{
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      width: 5,
                                      height: 20,
                                      marginRight: theme.spacing(1),
                                    }}
                                  />
                                )}
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                >
                                  {label.title}
                                </Typography>
                              </div>
                            </ListItemText>
                            <ListItemIcon>
                              <ShowChart />
                            </ListItemIcon>
                          </ListItem>
                        );
                      })}
                    </List>
                    <Paper
                      elevation={0}
                      style={{ marginTop: theme.spacing(2) }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <CloudDownloadOutlined />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              <strong>Download</strong>
                            </Typography>
                          }
                          secondary={
                            <div>
                              <ExportToExcel
                                Data={downloadData}
                                fileName={"download"}
                              />
                              <Button variant="text" disabled>csv</Button>
                            </div>
                          }
                        />
                      </ListItem>
                    </Paper>
                  </Grid>
                </Paper>
              )}

              {/* <SubscriptionsCard /> */}
              <div style={{ height: 25 }} />
            </Grid>

            {/* -------------------------__MAIN GRAPH_------------------------ */}
            <Grid
              item
              lg={7}
              style={{
                marginLeft: phoneScreen ? 5 : theme.spacing(5),
                marginBottom: phoneScreen ? theme.spacing(5) : 5,
              }}
            >
              {true ? (
                <div>
                  {isLoaded(firestoreCountries) &&
                    isLoaded(firestoreCommodities) && (
                      <div>
                        {/* --------PRICING DATA------------ */}
                        {indicator === "pricing" && (
                          <div>
                            {pricingData === undefined ? (
                              <CircularProgress style={{ marginLeft: "48%" }} />
                            ) : (
                              <Graphs
                                setdownloadData={setDownloadData}
                                countries={countries}
                                setCountries={setCountries}
                                memberships={Object.values(memberships)[0]}
                                indicator={indicator}
                                data={pricingData}
                                firestoreCountries={Object.keys(
                                  firestoreCountries
                                ).map((key) => ({
                                  ...firestoreCountries[key],
                                  id: key,
                                }))}
                                firestoreCommodities={Object.keys(
                                  firestoreCommodities
                                ).map((key) => ({
                                  ...firestoreCommodities[key],
                                  id: key,
                                }))}
                              />
                            )}
                          </div>
                        )}

                        {/* --------IMPORTS DATA------------ */}
                        {indicator === "imports" && (
                          <>
                            {importsData === undefined ? (
                              <CircularProgress style={{ marginLeft: "48%" }} />
                            ) : (
                              <Graphs
                                setdownloadData={setDownloadData}
                                countries={countries}
                                setCountries={setCountries}
                                memberships={Object.values(memberships)[0]}
                                indicator={indicator}
                                data={importsData}
                                firestoreCountries={Object.keys(
                                  firestoreCountries
                                ).map((key) => ({
                                  ...firestoreCountries[key],
                                  id: key,
                                }))}
                                firestoreCommodities={Object.keys(
                                  firestoreCommodities
                                ).map((key) => ({
                                  ...firestoreCommodities[key],
                                  id: key,
                                }))}
                              />
                            )}
                          </>
                        )}

                        {/* --------EXPORTS DATA------------ */}
                        {indicator === "exports" && (
                          <>
                            {exportsData === undefined ? (
                              <CircularProgress style={{ marginLeft: "48%" }} />
                            ) : (
                              <Graphs
                                setdownloadData={setDownloadData}
                                countries={countries}
                                setCountries={setCountries}
                                memberships={Object.values(memberships)[0]}
                                indicator={indicator}
                                data={exportsData}
                                firestoreCountries={Object.keys(
                                  firestoreCountries
                                ).map((key) => ({
                                  ...firestoreCountries[key],
                                  id: key,
                                }))}
                                firestoreCommodities={Object.keys(
                                  firestoreCommodities
                                ).map((key) => ({
                                  ...firestoreCommodities[key],
                                  id: key,
                                }))}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                </div>
              ) : (
                <>
                  <SignIn />
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default firestoreConnect([
  {
    collection: "countries",
    orderBy: ['name', 'asc'],
  },
  {
    collection: "commodities",
    orderBy: ['name', 'asc'],
  },
  {
    collection: "memberships",
  },
  {
    collection: "indicators",
  },
])(Agriculture);
