import XLSX from "xlsx";
import { storageRef } from "./firebase";
import { commodities } from "./resources/commodities";
import { countries } from "./resources/countries";


export const getExcelSheet = async (props) => {

  const agricultureFiles = {
    pricing: undefined,
    imports: undefined,
    exports: undefined,
  };

  await storageRef
    .child("agriculture")
    .child(props.country)
    .child("pricing.xlsx")
    .getDownloadURL()
    .then((url) => {
      agricultureFiles.pricing = url;
    });

  await storageRef
    .child("agriculture")
    .child(props.country)
    .child("imports.xlsx")
    .getDownloadURL()
    .then((url) => {
      agricultureFiles.imports = url;
    });

  await storageRef
    .child("agriculture")
    .child(props.country)
    .child("exports.xlsx")
    .getDownloadURL()
    .then((url) => {
      agricultureFiles.exports = url;
    });
    
  return agricultureFiles;
};

export const capitalizeFirstChar = (word) => {
  return `${word[0].toUpperCase() + word.slice(1, word.length)}`;
};

export const fetchData = async (_indicator) => {
  const handleFile = async (file, cntry) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const country_data = commodities
          .map((com) => com.value)
          .map((c) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            const ws = wb.Sheets[c];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            return {
              [c]: data,
            };
          });
        let lst = [];
        const temp2 = country_data.forEach((item) => {
          lst.push(Object.keys(item)[0]);
        });

        let temp3 = {};
        lst.forEach((item, i) => {
          temp3[item] = country_data[i][item];
        });
        temp3["isLoaded"] = true;
        resolve({ [cntry]: temp3 });
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    });
  };

  let tasks = [];
  // get urls
  countries.forEach((c) => tasks.push(getExcelSheet({ country: c.value })));
  const urls = await Promise.all(tasks);
  // get all file blobs
  tasks = [];
  for (let i = 0; i < countries.length; i++) {
    let url = urls[i];
    // setDownloadUrls((state) => ({ ...state, [countries[i].value]: url }));
    tasks.push(fetch(url[_indicator]).then((res) => res.blob()));
  }
  const blobs = await Promise.all(tasks);
  // get all excel data
  tasks = [];
  for (let i = 0; i < countries.length; i++) {
    tasks.push(handleFile(blobs[i], countries[i].value));
  }
  const data = await Promise.all(tasks);
  // combine all data to one object and return
  
  //  TRY combining with the download URLS and then bring back an object of data with type {files: data, urls: downloadUrls}
   return Object.assign({}, ...data);
};
