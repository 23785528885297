import { createStore } from "@reduxjs/toolkit";
import { createFirestoreInstance } from "redux-firestore";
// import firebase from "../../firebase"
import firebase from "../firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

const rrfConfig = {
  userProfile: "users",
};

// const sharenticStore = createStore(rootReducer, initialState)
export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export default store;

//Dont forget to pass it to provider
