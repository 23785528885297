import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link as Lnk } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link, Modal } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { auth, signInWithGoogle } from "../firebase";
import { theme } from "../theme";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://sharentic.co/">
        Sharentic
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(25),
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log("USER: ", auth.currentUser);
  }, [auth.currentUser]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError("Error signing in with password and email!");
      console.error("Error signing in with password and email", error);
    });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    console.log(name);

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Typography variant="h6" color="primary">
        Sign In
      </Typography>
      <p>Please sign in or sign up to access RandSandton data analytics.</p>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
        style={{ color: "white" }}
      >
        Sign In
      </Button>
      <Modal open={!auth.currentUser} onClose={handleClose}>
        <Container
          component="main"
          maxWidth="xs"
          style={{
            backgroundColor: "white",
            minHeight: "50%",
            alignSelf: "center",
          }}
        >
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <form className={classes.form} autoComplete="off" noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="userEmail"
                autoComplete="email"
                type="email"
                onFocus={() => {
                  setError(null);
                }}
                onChange={(event) => onChangeHandler(event)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="userPassword"
                label="Password"
                type="password"
                onChange={(event) => onChangeHandler(event)}
                id="password"
                onFocus={() => {
                  setError(null);
                }}
                autoComplete="current-password"
              />
              {error !== null && <Alert severity="error">{error}</Alert>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(event) => {
                  signInWithEmailAndPasswordHandler(event, email, password);
                }}
                className={classes.submit}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Sign In
                </Typography>
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="default"
                onClick={() => signInWithGoogle()}
                className={classes.submit}
              >
                <Typography variant="h6" color="primary">
                  Sign in with Google
                </Typography>
              </Button>
              <Grid container>
                <Grid item xs>
                  <Lnk style={{ textDecoration: "none" }} to="/password-reset">
                    <Link variant="body2">Forgot password?</Link>
                  </Lnk>
                </Grid>
                <Grid item>
                  <Lnk style={{ textDecoration: "none" }} to="/sign-up">
                    <Link variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Lnk>
                </Grid>
              </Grid>
            </form>
          </div>
          {/* <Box mt={8}>
          <Copyright />
        </Box> */}
        </Container>
      </Modal>
    </MuiThemeProvider>
  );
}
