import {
  Container,
  Grid,
  IconButton,
  Link,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Facebook, LinkedIn, Palette, Twitter } from "@material-ui/icons";
import { theme } from "../theme";

const Footer = () => {
  return (
    <>
      <footer style={{ backgroundColor: "rgba(0,0,0, 0.5)", paddingBottom: 0 }}>
        <Container maxWidth="lg">
          <Grid container spacing={8}>
            <Grid container item sm={4} direction="column">
              <Grid item>
                <Typography variant="subtitle1" color="primary">
                  <strong>ABOUT US</strong>
                </Typography>
              </Grid>
              <Grid item>
                <p>
                  Rand Sandton Consulting Group is a data analytics and
                  development research firm located in Bryanston, Johannesburg,
                  South Africa. The company provides data, research and advisory
                  services to leading organisations across the public and
                  private sectors.
                </p>
              </Grid>
            </Grid>
            {/* Second Column */}
            <Grid container item sm={4} direction="column">
              <Grid item>
                <Typography variant="subtitle1" color="primary">
                  <strong>OUR SERVICES</strong>
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Link
                  href={"/"}
                  style={{
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography>Data Analytics</Typography>
                </Link>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Link
                  href={"https://www.randsandton.com/development-consulting/"}
                  style={{
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography>Development Consulting</Typography>
                </Link>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Typography variant="subtitle1" color="primary">
                  <strong>OUR SECTORS</strong>
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Link
                  href={"https://www.randsandton.com/agriculture/"}
                  style={{
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography>Agriculture</Typography>
                </Link>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Link
                  href={"https://www.randsandton.com/energy-and-utilities/"}
                  style={{
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography>Energy and Utilities</Typography>
                </Link>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Link
                  href={"https://www.randsandton.com/financial-services/"}
                  style={{
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography>Financial Services</Typography>
                </Link>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Link
                  href={"https://www.randsandton.com/manufacturing/"}
                  style={{
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography>Manufacturing</Typography>
                </Link>
              </Grid>
              <Grid item style={{ paddingTop: 10 }}>
                <Link
                  href={"https://www.randsandton.com/metals-and-mining/"}
                  style={{
                    textTransform: "none",
                    color: theme.palette.text.primary,
                  }}
                >
                  <Typography>Metals and Mining</Typography>
                </Link>
              </Grid>
            </Grid>
            {/* Third Column */}
            <Grid container item sm={4} direction="column">
              <Grid item>
                <Typography variant="subtitle1" color="primary">
                  <strong>CONTACT US</strong>
                </Typography>
              </Grid>
              <Grid item>
                <p>
                  <strong>Physical address:</strong>
                  <br />
                  Suite 15, 22 Sloane Street, Bryanston, <br />
                  Sandton, 2191, South Africa <br />
                  <strong>Telephone: </strong>
                  <br />
                  +27 11 465 1585 <br />
                  <strong>Email:</strong>
                  <br />
                  info@randsandton.com
                </p>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" color="primary">
                  <strong>FOLLOW US</strong>
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  href={
                    "https://www.facebook.com/Rand-Sandton-Consulting-Group-331753574366226/"
                  }
                >
                  <Facebook fontSize="large" />
                </IconButton>
                <IconButton
                  href={
                    "https://www.linkedin.com/company/rand-sandton-consulting-group/about/"
                  }
                >
                  <LinkedIn fontSize="large" />
                </IconButton>
                <IconButton href={"https://twitter.com/GroupRand"}>
                  <Twitter fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Typography
          variant="subtitle1"
          style={{
            textAlign: "center",
            marginTop: theme.spacing(5),
            backgroundColor: "rgba(0,0,0,0.65)",
            // color: "white",
            padding: theme.spacing(3),
            paddingBottom: 0,
          }}
          color="primary"
        >
          Developed by Stanley Chideme & Honest Chirozva
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            backgroundColor: "rgba(0,0,0,0.65)",
          }}
        >
          <strong>stanleychideme123@outlook.com</strong>
        </Typography>
      </footer>
    </>
  );
};

export default Footer;
